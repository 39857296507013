if (module.hot) {
  module.hot.accept();
}

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import 'slick-carousel';
import 'waypoints/lib/noframework.waypoints.min.js';

var breakpointMobile = 768,
    isChanging = false,
    isFiltered = false;

$(document).ready(function(){

  var sectionWaypoints = [];
  $.each( $('.spoiler-row .spoiler-holder p .active-block'), function(index,value){
    sectionWaypoints.push(new Waypoint({
      element: value,
      handler: function(direction) {
        if(direction == 'down' && !$(value).closest('p').hasClass('visible')){
          console.log(value);
          var spoilerP = $(value).closest('p'),
              allSpoilers = $(value).closest('.spoiler-holder').find('p'),
              topSpoiler = $(value).closest('.spoiler-holder').find('.top-spoiler'),
              spoilerList = spoilerP.find('span'),
              spoilerIndex = spoilerList.index(value),
              topSpoilerList = topSpoiler.find('span'),
              remainingSpoilers = topSpoiler.find('span:not(.selectable)'),
              remainingSpoilerIndex = remainingSpoilers.index(this);
          spoilerP.addClass('visible');
          console.log(topSpoilerList, spoilerIndex);
          topSpoilerList[spoilerIndex].classList.add('selectable');
          topSpoilerList[spoilerIndex].setAttribute('aria-hidden', 'false');
        }
      },
      offset: "70%"
    }));
  })

  /*$('.spoiler-holder').on('click', 'p.top-spoiler span:not(.selectable)', function(e){
    var topSpoiler = $(this).closest('p'),
        allSpoilers = $(this).closest('.spoiler-holder').find('p'),
        spoilerList = topSpoiler.find('span'),
        spoilerIndex = spoilerList.index(this),
        remainingSpoilers = topSpoiler.find('span:not(.selectable)'),
        remainingSpoilerIndex = remainingSpoilers.index(this);

    $.each(allSpoilers, function(i,v){
      var spoilerSpanList = $(v).find('span');
      spoilerSpanList[spoilerIndex].classList.add('selectable');
      spoilerSpanList[spoilerIndex].setAttribute('aria-hidden', 'false')
    })
    allSpoilers[spoilerIndex].classList.add('visible');

    if (remainingSpoilerIndex + 1 == remainingSpoilers.length){
      allSpoilers[remainingSpoilerIndex].classList.remove('top-spoiler');
      if(remainingSpoilers.length > 1){
        allSpoilers[remainingSpoilerIndex-1].classList.add('top-spoiler');
      }
    }
  } ).on('keypress', 'p.top-spoiler a', function(e){
    $(this).find('span:not(.selectable)').click();
    this.removeAttribute('aria-label');
    this.removeAttribute('tabindex');
  } )*/

  $('.spoiler-row').on('transitionend', 'p', function(e){
    if($(this).find('span:not(.selectable)').length == 0){
      $(this).closest('.spoiler-row').addClass('spoiler-revealed');
      $(this).closest('.spoiler-holder').off();
    }
  })

})
